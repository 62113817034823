.popupContainer {
  width: 304px;
  height: 544px;
  border-radius: 16px;
  background: var(--grayscale-gs-white);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.10);
}

@media (prefers-color-scheme: dark) {
  .popupContainer {
    background-color: var(--grayscale-gs-primary);
    color: var(--grayscale-gs-background);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
  }
}