.ul {
  margin: unset;
  padding-left: 25px;
}

.ul_none {
  list-style-type: none;
}

.ul_initial {
  list-style: initial;
}