.line {
  transition: .3s;
}

.line1 {
  transform: rotate(45deg) translateX(5px) translateY(-5px);
}

.line2 {
  transform: rotate(-45deg) translateX(-15px) translateY(5px);
}

.line3 {
  transform: rotate(-45deg) translateX(-12px) translateY(-2px);
}