$tiny: 4px;
$small: 8px;
$middle: 12px;
$medium: 16px;
$normal: 24px;
$large: 32px;

.tiny {
  padding: $tiny;
}

.tinyVR {
  padding-top: $tiny;
  padding-bottom: $tiny;
}

.tinyHR {
  padding-left: $tiny;
  padding-right: $tiny;
}

.tinyTop {
  padding-top: $tiny;
}

.tinyRight {
  padding-right: $tiny;
}

.tinyBottom {
  padding-bottom: $tiny;
}

.tinyLeft {
  padding-left: $tiny;
}

.small {
  padding: $small;
}

.smallVR {
  padding-top: $small;
  padding-bottom: $small;
}

.smallHR {
  padding-right: $small;
  padding-left: $small;
}

.smallTop {
  padding-top: $small;
}

.smallRight {
  padding-right: $small;
}

.smallBottom {
  padding-bottom: $small;
}

.smallLeft {
  padding-left: $small;
}

.middle {
  padding: $middle;
}

.middleVR {
  padding-top: $middle;
  padding-bottom: $middle;
}

.middleHR {
  padding-right: $middle;
  padding-left: $middle;
}

.middleTop {
  padding-top: $middle;
}

.middleRight {
  padding-right: $middle;
}

.middleBottom {
  padding-bottom: $middle;
}

.middleLeft {
  padding-left: $middle;
}

.medium {
  padding: $medium;
}

.mediumVR {
  padding-top: $medium;
  padding-bottom: $medium;
}

.mediumHR {
  padding-right: $medium;
  padding-left: $medium;
}

.mediumTop {
  padding-top: $medium;
}

.mediumRight {
  padding-right: $medium;
}

.mediumBottom {
  padding-bottom: $medium;
}

.mediumLeft {
  padding-left: $medium;
}

.normal {
  padding: $normal;
}

.normalVR {
  padding-top: $normal;
  padding-bottom: $normal;
}

.normalHR {
  padding-right: $normal;
  padding-left: $normal;
}

.normalTop {
  padding-top: $normal;
}

.normalRight {
  padding-right: $normal;
}

.normalBottom {
  padding-bottom: $normal;
}

.normalLeft {
  padding-left: $normal;
}

.large {
  padding: $large;
}

.largeVR {
  padding-top: $large;
  padding-bottom: $large;
}

.largeHR {
  padding-right: $large;
  padding-left: $large;
}

.largeTop {
  padding-top: $large;
}

.largeRight {
  padding-right: $large;
}

.largeBottom {
  padding-bottom: $large;
}

.largeLeft {
  padding-left: $large;
}
