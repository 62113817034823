@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-VariableFont_wght.ttf") format('truetype');
}

body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  padding: 0;
  margin: 0;

  background-color: var(--grayscale-gs-background);
  color: var(--grayscale-gs-primary);
}

a {
  text-decoration: none;
}

h1, h2 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--grayscale-gs-primary);
    color: var(--grayscale-gs-background);
  }
}