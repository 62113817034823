.button {
  border: unset;
  cursor: pointer;
  transition: .3s;
  padding: 0;
  display: block;
  background-color: transparent;
  color: unset;
  text-align: unset;
}

.primary {
  background-color: var(--primary);
  border-radius: 1000px;

  & * {
    color: var(--grayscale-gs-white);
  }

  &_icon * {
    fill: var(--grayscale-gs-white);
  }

  &:hover {
    background-color: var(--primary-hover);
  }

  &:active {
    background-color: var(--primary-pressed);
  }
}

.light {
  background-color: var(--primary-light);
  border-radius: 1000px;

  & * {
    color: var(--grayscale-gs-white);
  }

  &_icon * {
    fill: var(--grayscale-gs-white);
  }

  &:hover {
    background-color: var(--primary-light-hover);
  }

  &:active {
    background-color: var(--primary-light-pressed);
  }
}

.white {
  background-color: var(--grayscale-gs-white);
  border-radius: 1000px;

  & * {
    color: var(--black);
  }

  &_icon * {
    fill: var(--black);
  }

  &:hover {
    background-color: var(--grayscale-gs-tetriary-2);
  }

  &:active {
    background-color: var(--grayscale-gs-tetriary-1);
  }
}

.link {
  background-color: transparent;
  border-radius: 1000px;

  & * {
    color: var(--primary);
  }

  &_icon * {
    fill: var(--primary);
  }

  &:hover {
    background-color: var(--grayscale-gs-background-darker);
  }

  &:active {
    background-color: var(--grayscale-gs-quarterly-1);
  }
}

.black {
  background-color: var(--black);
  border-radius: 1000px;

  & * {
    color: var(--grayscale-gs-white);
  }

  &_icon * {
    fill: var(--grayscale-gs-white);
  }

  &:hover {
    background-color: var(--grayscale-gs-primary);
  }

  &:active {
    background-color: var(--primary-2);
  }
}

.block {
  width: 100%;
}

.selected {
  color: var(--primary-pressed);

  &_icon svg * {
    fill: var(--primary-pressed);
  }
}

.disabled {
  background-color: var(--grayscale-gs-tetriary-2);
  cursor: not-allowed;
  border-radius: 1000px;

  &_link {
    background-color: unset;

    & * {
      color: var(--grayscale-gs-tetriary-2)!important;
    }

    &_icon * {
      fill: var(--grayscale-gs-tetriary-2) !important;
    }
  }

  & * {
    color: var(--grayscale-gs-white);
  }

  &_icon * {
    fill: var(--grayscale-gs-white);
  }
}