.checkBoxContainer {
  border-radius: 8px;
  background-color: var(--primary-light);
  color: var(--primary);
  width: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--primary-light-hover);
  }

  &:active {
    background-color: var(--primary-light-pressed);
  }
}

.checked {
  background-color: var(--primary);
  color: var(--grayscale-gs-white);

  &:hover {
    background-color: var(--primary-hover);
  }

  &:active {
    background-color: var(--primary-pressed);
  }
}

.checkedIcon {
  position: absolute;
  right: 4px;
  top: 4px;
  opacity: 0;
  transform: scale(0.001);
  transition: .3s;

  &_checked {
    opacity: 1;
    transform: scale(1);
  }
}