.switchBox {
  & * {
    color: var(--grayscale-gs-secondary-1);
    fill: var(--grayscale-gs-secondary-1);
  }

  &_disabled {
    cursor: not-allowed;

    & * {
      color: var(--grayscale-gs-tetriary-1)!important;
      fill: var(--grayscale-gs-tetriary-1)!important;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .switchBox {
    & * {
      color: var(--grayscale-gs-tetriary-1);
      fill: var(--grayscale-gs-tetriary-1);
    }

    &_disabled {
      cursor: not-allowed;

      & * {
        color: var(--grayscale-gs-secondary-1)!important;
        fill: var(--grayscale-gs-secondary-1)!important;
      }
    }
  }
}