.section {
  min-height: 544px;
  background: var(--grayscale-gs-white);
  border-radius: 24px;
  width: 80%;
  margin: 120px 10%;
  padding: 80px 96px;
  position: relative;
}

@media (prefers-color-scheme: dark) {
  .section {
    background-color: var(--black);
    color: var(--grayscale-gs-background);
  }
}

@media (max-width: 990px) {
  .section {
    padding: 24px;
    margin: 96px 10%;
  }
}