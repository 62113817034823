.main {
  position: relative;
  backdrop-filter: blur(16px);
  padding-bottom: 1px;
  background-color: color-mix(in srgb, var(--grayscale-gs-background) 50%, white 0%);
}

@media (prefers-color-scheme: dark) {
  .main {
    background-color: color-mix(in srgb, var(--grayscale-gs-primary) 50%, white 0%);
  }
}