.camera1, .camera2 {
}

.container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.blank {
  width: 100vw;
  height: 100vh;
}

.h1 {
  color: var(--primary, #4D5DFF);
  text-align: center;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: -1.92px;
}

.h2 {
  color: var(--grayscale-gs-primary);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.addToChrome {
  min-width: 188px;
}

@media (max-width: 990px) {
  .h1 {
    font-size: 62px;
  }
  .camera1, .camera2 {
    padding-top: 50px;
    & img {
      width: 104px;
    }
  }
}