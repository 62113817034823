.accentedText {
  display: inline-block;
  position: relative;
  color: var(--primary);

  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 40%;
    bottom: -4px;

    border-radius: 1000px;
    background: var(--primary);
  }

  &_left:before {
    left: 0;
  }

  &_center:before {
    left: 30%;
  }

  &_right:before {
    right: 0;
  }
}