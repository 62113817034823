.navDesktop {
  & .ul {
    display: flex;
    gap: 24px;
    margin: unset;
    list-style-type: none;
    padding: unset;
    align-items: center;
  }
}

.navMobile {
   width: 100%;
   height: 100%;

   & .ul {
     display: flex;
     gap: 16px;
     width: 100%;
     height: 100%;
     flex-direction: column;
     justify-content: center;
     margin: unset;
     list-style-type:  none;
     padding: unset;
   }
 }