.buttonDesktop {
  color: var(--grayscale-gs-secondary-1);
  background-color: transparent;
  border-radius: 8px;
  position: relative;
  padding: 8px;
  line-height: 100%;
  transition: .3s;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--grayscale-gs-secondary-1);
    opacity: 0;
    transition: .3s;
  }

  &:hover, &:active {
    &:after {
      bottom: 6px;
      left: 8px;
      width: calc(100% - 16px);
      opacity: 1;
    }
  }

  &:active {
    background-color: color-mix(in srgb, var(--grayscale-gs-primary) 20%, white 0%);
  }
}

.buttonMobile {
  color: var(--grayscale-gs-secondary-1);
  background-color: transparent;
  border-radius: 16px;
  padding: 16px;
  line-height: 100%;
  transition: .3s;

  &:active {
    background: var(--grayscale-gs-quarterly-1);
  }
}

.selected {
  color: var(--primary);

  &:after {
    background-color: var(--primary);
  }
}