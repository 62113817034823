.wholePageImage {
  height: 213px;
}

.image1, .image2 {
  width: 85px;
}

.image2 {
  transform: rotate(180deg);
}