.button {
  background: unset;
  border: unset;
  cursor: pointer;
  color: var(--grayscale-gs-secondary-1);
  transition: .3s;
  overflow: hidden;
  position: relative;
  border-radius: 8px;

  &:hover {
    background-color: var(--grayscale-gs-white);
    & * {
      color: var(--grayscale-gs-primary);
      fill: var(--grayscale-gs-primary);
    }
  }

  &:active {
    background-color: var(--grayscale-gs-secondary-1);
    color: var(--grayscale-gs-primary);
    fill: var(--grayscale-gs-primary);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 35%;
    height: 2px;
    width: 30%;
    background-color: var(--primary);
    transition: .3s;
  }

  & * {
    fill: var(--grayscale-gs-secondary-1);
  }
}

.selected {
  color: var(--primary);

  & * {
    fill: var(--primary);
  }

  &:before {
    bottom: 5px;
  }

  &:hover {
    & * {
      color: var(--primary);
      fill: var(--primary);
    }
  }

  &:active {
    color: var(--primary);
    fill: var(--primary);
  }
}