.flexBox {
  flex-flow: unset;
}

.gap {
  &_tiny {
    gap: 4px;
  }

  &_small {
    gap: 8px;
  }

  &_normal {
    gap: 12px;
  }

  &_medium {
    gap: 16px;
  }

  &_middle {
    gap: 24px;
  }

  &_large {
    gap: 32px;
  }

  &_extraLarge {
    gap: 64px;
  }
}

.column {
  flex-direction: column!important;
}

.flexWrap {
  flex-wrap: wrap;
}