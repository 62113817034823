.size_tiny, .size_tiny * {
  font-size: 14px;
}

.size_small, .size_small * {
  font-size: 18px;
}

.size_middle, .size_middle * {
  font-size: 20px;
}

.size_normal, .size_normal * {
  font-size: 24px;
}

.size_big, .size_big * {
  font-size: 40px;
}

.size_large, .size_large * {
  font-size: 64px;
}

.size_extraLarge, .size_extraLarge * {
  font-size: 98px;
}

.weight_regular, .weight_regular * {
  font-weight: 400;
}

.weight_bold, .weight_bold * {
  font-weight: 700;
}

.weight_extraBold, .weight_extraBold * {
  font-weight: 800;
}

.secondary, .secondary * {
  color: var(--grayscale-gs-secondary-1);
}