.text {
  font-family: Nunito, sans-serif;
  line-height: 100%;
}

.size_tiny {
  font-size: 12px;
}

.size_small {
  font-size: 14px;
}

.size_middle {
  font-size: 16px;
}

.size_large {
  font-size: 22px;
}

.weight_regular {
  font-weight: 400;
}

.weight_semiBold {
  font-weight: 600;
}

.weight_bold {
  font-weight: 700;
}