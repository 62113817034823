.infoContainer {
  width: 632px;
  border-radius: 24px;
  padding: 40px;
  margin-top: 88px;
  margin-bottom: 60px;
  color: var(--grayscale-gs-primary);
  background: var(--grayscale-gs-white);
}

@media (prefers-color-scheme: dark) {
  .infoContainer {
    background-color: var(--black);
    color: var(--grayscale-gs-secondary-2);
  }
}