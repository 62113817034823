.container {
  height: 100vh;
}

@media (max-width: 990px) {
  .h1 {
    font-size: 62px;
  }
  .camera1, .camera2 {
    padding-top: 50px;
    & img {
      width: 104px;
    }
  }
}