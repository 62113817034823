.title {
  background: linear-gradient(#8B04FB, #2304FB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &_white {
    color: var(--grayscale-gs-white);
  }

  &_desktop {
    font-size: 32px!important;
  }

  &_mobile {
    font-size: 22px!important;
  }
}