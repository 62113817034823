.button {
  border-radius: 8px;
  background: var(--primary);
  color: var(--grayscale-gs-white);
  cursor: pointer;

  padding: 0;
  margin: 0;
  height: unset!important;
  transition: .3s;

  &:hover {
    background: var(--primary-hover);
  }

  &:active {
    background: var(--primary-pressed);
  }

  & * {
    fill: var(--grayscale-gs-white);
  }
}

.disabled {
  background: var(--grayscale-gs-tetriary-2)!important;
  cursor: not-allowed;

  &:hover {
    background: var(--grayscale-gs-tetriary-2)!important;
  }
}