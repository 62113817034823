.header {
  background-color: var(--grayscale-gs-white);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.10);
  position: relative;

  &_desktop {
    border-radius: 16px;
    min-width: 80vw;
  }

  &_mobile {
    padding: 8px 20px;
  }
}

.addToChrome {
  min-width: 188px;
}

.edgeButton {
  background: var(--primary-light);
}

.iconMobile {
  width: 32px;
  height: 32px;
}

.menu {
  z-index: 10;
  position: fixed;
  top: 64px;
  right: -100%;
  background: inherit;
  height: calc(100% - 64px);
  width: 100%;
  transition: .3s;
  padding: 20px;

  &_visible {
    right: 0;
  }

  &_hidden {
    right: -100%;
  }

  &_hiddenDone {
    right: -100%;
  }
}

.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

@media (prefers-color-scheme: dark) {
  .header {
    background-color: var(--black);
  }
}